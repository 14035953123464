import React from "react";
import "./ReviewCard.css";

const ReviewCard = ({ header, content, icon, name }) => {
  return (
    <div className="review_card">
      <div className="review_text_wrapper">
        <div className="review_header">
          <p>{header}</p>
        </div>
        <div className="review_content">
          <p>{content}</p>
        </div>
      </div>
      <div className="review_user_wrapper">
        <div className="user_icon">
          <img src={icon} alt="icon" />
        </div>
        <div className="user_name">
          <p>
            {name}
            <span>님</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
