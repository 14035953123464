import React, { useRef } from "react";
import MynailNavigation from "./components/Navigation/MynailNavigation/MynailNavigation";
import HeroBlock from "./blocks/HeroBlock/HeroBlock";
import FeatureBlock from "./blocks/FeatureBlock/FeatureBlock";
import FooterBlock from "./blocks/FooterBlock/FooterBlock";
import ReviewBlock from "./blocks/ReviewBlock/ReviewBlock";
import MapBlock from "./blocks/MapBlock/MapBlock";
import InquireBlock from "./blocks/InquireBlock/InquireBlock";

const LandingPage = () => {
  const homeRef = useRef(null);
  const productRef = useRef(null);
  const reviewRef = useRef(null);
  const mapRef = useRef(null);
  const inquireRef = useRef(null);

  const scrollToBlock = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="mynail_nav" ref={homeRef}>
        <MynailNavigation
          scrollToBlock={scrollToBlock}
          refs={{ homeRef, productRef, reviewRef, mapRef, inquireRef }}
        />
      </div>

      <HeroBlock />

      <div className="mynail_feature" ref={productRef}></div>
      <FeatureBlock />

      <div className="mynail_review" ref={reviewRef}></div>
      <ReviewBlock />

      <div className="mynail_map" ref={mapRef}></div>
      <MapBlock />

      <div className="mynail_inquire" ref={inquireRef}></div>
      <InquireBlock />

      <FooterBlock />
    </>
  );
};

export default LandingPage;
