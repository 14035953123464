import React, { useState } from "react";
import MynailLogo from "../../../assets/icon-components/MynailLogo/MynailLogo";
import "./MobileNavigationBar.css";
import BurgerIcon from "../../../assets/icon-components/BurgerIcon/BurgerIcon";
import CloseIcon from "../../../assets/icon-components/CloseIcon/CloseIcon";

const MobileNavigationBar = ({ scrollTo, refs }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleMenuCLick = (ref) => {
    scrollTo(ref);
    setIsMenuOpen(false);
  };

  return (
    <>
      <div className="mobile_nav_container">
        <div
          onClick={() => scrollTo(refs.homeRef)}
          className="mobile_nav_logo_container"
        >
          <MynailLogo />
        </div>

        <button className="mobile_nav_burger_container" onClick={toggleMenu}>
          {isMenuOpen ? <CloseIcon /> : <BurgerIcon />}
        </button>

        {isMenuOpen && (
          <div className={`mobile_nav_menu ${isMenuOpen ? "show" : ""}`}>
            <ul>
              <li onClick={() => handleMenuCLick(refs.productRef)}>
                서비스 소개
              </li>
              <li onClick={() => handleMenuCLick(refs.reviewRef)}>고객후기</li>
              <li onClick={() => handleMenuCLick(refs.mapRef)}>
                이용 가능 지역
              </li>
              <li onClick={() => handleMenuCLick(refs.inquireRef)}>입점문의</li>
            </ul>
          </div>
        )}
      </div>
      <div className="mobile_nav_container_background"></div>
    </>
  );
};

export default MobileNavigationBar;
