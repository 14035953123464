import React from "react";

function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <g clipPath="url(#clip0_129_838)">
        <path
          stroke="#484848"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4.511 4.596L19.49 19.573m-14.978 0L19.49 4.596"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_129_838">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="translate(0 .084)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CloseIcon;
