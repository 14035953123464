import React, { useEffect } from "react";
import { heroImages } from "../../../assets/hero-assets/hero-product-images";
import { useAnimation, motion } from "framer-motion";
import "./HeroDesktopAnimation.css";

const HeroDesktopAnimation = (inView) => {
  const purpleControlsDesktop = useAnimation();
  const nailClipperControlsDesktop = useAnimation();
  const pusherControlsDesktop = useAnimation();
  const gelLampControlsDesktop = useAnimation();
  const pinkControlsDesktop = useAnimation();
  const skyblueControlsDesktop = useAnimation();

  const springTransition = {
    type: "spring",
    stiffness: 100,
    damping: 65,
    mass: 1,
    delay: 0,
  };

  useEffect(() => {
    if (inView) {
      purpleControlsDesktop.start({
        x: "-6.953vw",
        y: "-5.792vh",
        rotate: -41.83,
        transition: {
          ...springTransition,
          ease: "easeIn",
        },
      });
      nailClipperControlsDesktop.start({
        x: "-3.516vw",
        y: "49.544vh",
        rotate: -9.28,
        transition: {
          ...springTransition,
          ease: "easeIn",
        },
      });
      pusherControlsDesktop.start({
        x: "44.426vw",
        y: "-9.124vh",
        rotate: 1.89,
        transition: {
          ...springTransition,
          ease: "easeIn",
        },
      });
      gelLampControlsDesktop.start({
        x: "80.547vw",
        y: "17.11vh",
        rotate: -22.03,
        transition: {
          ...springTransition,
          ease: "easeIn",
        },
      });
      pinkControlsDesktop.start({
        x: "8.047vw",
        y: "73.936vh",
        rotate: -8.28,
        transition: {
          ...springTransition,
          ease: "easeIn",
        },
      });
      skyblueControlsDesktop.start({
        x: "76.016vw",
        y: "65.832vh",
        rotate: -2.9,
        transition: {
          ...springTransition,
          ease: "easeIn",
        },
      });
    }
  }, [
    purpleControlsDesktop,
    nailClipperControlsDesktop,
    pusherControlsDesktop,
    gelLampControlsDesktop,
    pinkControlsDesktop,
    skyblueControlsDesktop,
  ]);

  return (
    <div className="mynail_hero_background_products_desktop">
      <motion.div
        className="mynail_hero_purple"
        style={{
          backgroundImage: `url(${heroImages.purple})`,
        }}
        initial={{ x: "-16.094vw", y: "-12.011vh", rotate: -41.83 }}
        animate={purpleControlsDesktop}
      />
      <motion.div
        className="mynail_hero_nail_clipper"
        style={{
          backgroundImage: `url(${heroImages.nailClipper})`,
        }}
        initial={{ x: "-9.219vw", y: "44.535vh", rotate: -9.28 }}
        animate={nailClipperControlsDesktop}
      />

      <motion.div
        className="mynail_hero_pusher"
        style={{
          backgroundImage: `url(${heroImages.pusher})`,
        }}
        initial={{ x: "45.207vw", y: "-29.77vh", rotate: 1.89 }}
        animate={pusherControlsDesktop}
      />

      <motion.div
        className="mynail_hero_gel_lamp"
        style={{
          backgroundImage: `url(${heroImages.gelLamp})`,
        }}
        initial={{ x: "83.828vw", y: "6.083vh", rotate: -22.03 }}
        animate={gelLampControlsDesktop}
      />

      <motion.div
        className="mynail_hero_pink"
        style={{
          backgroundImage: `url(${heroImages.pink})`,
        }}
        initial={{ x: "-0.078vw", y: "80.985vh", rotate: -8.28 }}
        animate={pinkControlsDesktop}
      />

      <motion.div
        className="mynail_hero_skyblue"
        style={{
          backgroundImage: `url(${heroImages.skyblue})`,
        }}
        initial={{ x: "91.631vw", y: "77.438vh", rotate: -2.9 }}
        animate={skyblueControlsDesktop}
      />
    </div>
  );
};

export default HeroDesktopAnimation;
