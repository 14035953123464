import { useAnimation, motion } from "framer-motion";
import React, { useEffect } from "react";
import { heroImages } from "../../../assets/hero-assets/hero-product-images";
import "./HeroMobileAnimation.css";

const HeroMobileAnimation = (inView) => {
  const purpleControlsMobile = useAnimation();
  const nailClipperControlsMobile = useAnimation();
  const gelLampControlsMobile = useAnimation();
  const skyblueControlsMobile = useAnimation();

  const springTransition = {
    type: "spring",
    stiffness: 100,
    damping: 65,
    mass: 1,
    delay: 0,
  };

  useEffect(() => {
    if (inView) {
      purpleControlsMobile.start({
        x: "-17.067vw",
        y: "5.717vh",
        rotate: -41.83,
        transition: {
          ...springTransition,
          ease: "easeIn",
        },
      });
      nailClipperControlsMobile.start({
        x: "-20.8vw",
        y: "63.623vh",
        rotate: -10.42,
        transition: {
          ...springTransition,
          ease: "easeIn",
        },
      });
      gelLampControlsMobile.start({
        x: "72.133vw",
        y: "5.857vh",
        rotate: -24.49,
        transition: {
          ...springTransition,
          ease: "easeIn",
        },
      });
      skyblueControlsMobile.start({
        x: "63.333vw",
        y: "65.073vh",
        rotate: -9.73,
        transition: {
          ...springTransition,
          ease: "easeIn",
        },
      });
    }
  }, [
    purpleControlsMobile,
    nailClipperControlsMobile,
    gelLampControlsMobile,
    skyblueControlsMobile,
  ]);

  return (
    <div className="mynail_hero_background_products_mobile">
      <motion.div
        className="mynail_hero_purple"
        style={{
          backgroundImage: `url(${heroImages.purple})`,
        }}
        initial={{ x: "-29.6vw", y: "-3.488vh", rotate: -41.83 }}
        animate={purpleControlsMobile}
      />

      <motion.div
        className="mynail_hero_nail_clipper"
        style={{
          backgroundImage: `url(${heroImages.nailClipper})`,
        }}
        initial={{ x: "-34.4vw", y: "69.355vh", rotate: -10.58 }}
        animate={nailClipperControlsMobile}
      />

      <motion.div
        className="mynail_hero_gel_lamp"
        style={{
          backgroundImage: `url(${heroImages.gelLamp})`,
        }}
        initial={{ x: "82.533vw", y: "2.327vh", rotate: -24.82 }}
        animate={gelLampControlsMobile}
      />

      <motion.div
        className="mynail_hero_skyblue"
        style={{
          backgroundImage: `url(${heroImages.skyblue})`,
        }}
        initial={{ x: "73.333vw", y: "77.533vh", rotate: -9.87 }}
        animate={skyblueControlsMobile}
      />
    </div>
  );
};

export default HeroMobileAnimation;
