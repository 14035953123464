const MynailLogo = ({ width = 124, height = 24 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          fill="#515151"
          d="M55.717 4.597h8.89v9.643c0 .795-.373 1.261-1.18 1.261H57.98c-.434 0-1.082-.427-1.494-.854-.394-.389-.768-.95-.768-1.3v-8.75Zm6.708 9.022v-7.14H57.9v6.5c0 .506.196.642.63.642h3.895v-.002ZM67.537 3.26h2.183v5.666h2.891v1.882h-2.89v8.42c0 .33-.492.445-1.102.445-.69 0-1.082-.154-1.082-.445V3.26ZM78.63 4.772c2.93 0 5.036 1.921 5.036 4.968 0 3.046-2.105 4.968-5.037 4.968-2.931 0-5.036-1.921-5.036-4.968 0-3.047 2.105-4.968 5.036-4.968Zm0 1.883c-1.672 0-2.873 1.164-2.873 3.085s1.201 3.085 2.872 3.085c1.672 0 2.872-1.164 2.872-3.085s-1.2-3.085-2.872-3.085Zm9.579 12.57c0 .312-.452.447-1.081.447-.63 0-1.102-.137-1.102-.446V3.26h2.183v15.968-.002ZM93.398 15.21c-.55 0-.906-.33-1.318-.757-.394-.388-.747-.873-.747-1.223V4.597h2.183v8.149c0 .466.216.582.59.582h4.564c.334 0 .472.35.472.95s-.138.932-.472.932h-5.272Zm7.1-11.756h2.143v15.578c0 .291-.315.446-1.062.446-.747 0-1.081-.155-1.081-.446V9.895h-3.56V8.012h3.56V3.454Zm3.876-.194h2.163v15.968c0 .29-.335.445-1.082.445-.747 0-1.081-.154-1.081-.445V3.26ZM113.399 11.427c-2.715 0-4.582-1.9-4.582-4.151s1.869-4.152 4.582-4.152 4.583 1.9 4.583 4.152c0 2.25-1.87 4.151-4.583 4.151Zm0-6.461c-1.514 0-2.42 1.028-2.42 2.308 0 1.28.906 2.31 2.42 2.31 1.514 0 2.418-1.028 2.418-2.31s-.904-2.308-2.418-2.308Zm.687 15.967c-.355 0-.806-.31-1.161-.64-.413-.39-.708-.815-.708-1.145v-3.317h9.008v-1.378h-9.008v-1.766h10.603c.433 0 .59.098.59.543v4.366h-9.008v1.143c0 .253.138.428.433.428h8.713c.334 0 .452.29.452.893 0 .68-.157.873-.452.873h-9.462Zm7.337-17.868h2.183v8.42c0 .329-.473.445-1.081.445-.689 0-1.102-.155-1.102-.446v-8.42Z"
        />
        <path
          fill="#6C44FD"
          d="M37.507 1.256c-.017-.018-.037-.033-.054-.051-2.467.488-4.36 2.597-4.437 5.183a5.383 5.383 0 0 0 .938 3.197l.978-.926a4.411 4.411 0 0 1 4.12-1.069c.987.325 1.753.816 2.3 1.478.062.075.18.007.142-.081-.932-2.155-3.15-7.138-3.985-7.729l-.002-.002Z"
        />
        <path
          fill="#6C44FD"
          d="M39.5 9.05a2.503 2.503 0 0 0-3.057 0c-1.42 1.095-3.315 3.69-3.315 9.74v1.849c0 .68.264 1.34.76 1.814.738.702 2.047 1.547 4.084 1.547 2.037 0 3.26-.788 4.01-1.475.531-.488.834-1.17.834-1.888v-1.848c0-6.049-1.896-8.644-3.315-9.74H39.5ZM34.773 7.287c2.04 0 3.694-1.632 3.694-3.644C38.467 1.631 36.813 0 34.773 0S31.08 1.631 31.08 3.643c0 2.012 1.654 3.644 3.693 3.644Z"
        />
        <path
          fill="#6C44FD"
          d="M34.271 7.778c2.04 0 3.694-1.63 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.63-3.693 3.643c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#6D44FD"
          d="M33.77 8.27c2.04 0 3.694-1.631 3.694-3.643 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.63-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#6D45FD"
          d="M33.268 8.764c2.04 0 3.694-1.631 3.694-3.644 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.631-3.693 3.643c0 2.013 1.653 3.644 3.693 3.644Z"
        />
        <path
          fill="#6D45FD"
          d="M32.768 9.256c2.04 0 3.693-1.632 3.693-3.644 0-2.012-1.653-3.643-3.693-3.643S29.074 3.6 29.074 5.612c0 2.012 1.654 3.644 3.694 3.644Z"
        />
        <path
          fill="#6D45FD"
          d="M32.266 9.747c2.04 0 3.693-1.63 3.693-3.643 0-2.012-1.654-3.643-3.693-3.643-2.04 0-3.694 1.63-3.694 3.643 0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#6E45FD"
          d="M31.765 10.241c2.04 0 3.694-1.631 3.694-3.643 0-2.012-1.654-3.644-3.694-3.644s-3.694 1.632-3.694 3.644c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#6E46FD"
          d="M31.263 10.733c2.04 0 3.693-1.631 3.693-3.644 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.631-3.694 3.643c0 2.013 1.654 3.644 3.694 3.644Z"
        />
        <path
          fill="#6E46FD"
          d="M30.762 11.225c2.04 0 3.694-1.632 3.694-3.644 0-2.012-1.654-3.643-3.694-3.643S27.07 5.569 27.07 7.581c0 2.012 1.654 3.644 3.693 3.644Z"
        />
        <path
          fill="#6E46FD"
          d="M30.26 11.718c2.04 0 3.694-1.631 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.694 1.63-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#6F46FD"
          d="M29.76 12.21c2.04 0 3.693-1.631 3.693-3.643 0-2.012-1.653-3.644-3.693-3.644s-3.694 1.632-3.694 3.644c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#6F46FD"
          d="M29.257 12.702c2.04 0 3.694-1.631 3.694-3.644 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.631-3.693 3.643c0 2.013 1.653 3.644 3.693 3.644Z"
        />
        <path
          fill="#6F47FD"
          d="M28.757 13.195c2.04 0 3.693-1.63 3.693-3.643 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.631-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#6F47FD"
          d="M28.255 13.687c2.04 0 3.693-1.631 3.693-3.643 0-2.012-1.654-3.643-3.693-3.643-2.04 0-3.694 1.63-3.694 3.643 0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#7047FD"
          d="M27.754 14.179c2.04 0 3.694-1.631 3.694-3.643 0-2.012-1.654-3.644-3.694-3.644s-3.694 1.632-3.694 3.644c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#7047FD"
          d="M27.252 14.673c2.04 0 3.693-1.632 3.693-3.644 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.631-3.694 3.643c0 2.012 1.654 3.644 3.694 3.644Z"
        />
        <path
          fill="#7048FD"
          d="M26.751 15.164c2.04 0 3.694-1.63 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.631-3.693 3.643c0 2.012 1.654 3.643 3.693 3.643Z"
        />
        <path
          fill="#7048FD"
          d="M26.249 15.656c2.04 0 3.694-1.631 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.694 1.63-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#7148FD"
          d="M25.748 16.15c2.04 0 3.694-1.631 3.694-3.643 0-2.013-1.654-3.644-3.694-3.644s-3.693 1.631-3.693 3.644c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#7148FD"
          d="M25.246 16.642c2.04 0 3.694-1.632 3.694-3.644 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.631-3.693 3.643c0 2.012 1.653 3.644 3.693 3.644Z"
        />
        <path
          fill="#7149FD"
          d="M24.746 17.133c2.04 0 3.693-1.63 3.693-3.643 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.631-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#7149FD"
          d="M24.244 17.627c2.04 0 3.693-1.631 3.693-3.643 0-2.012-1.654-3.644-3.693-3.644-2.04 0-3.694 1.632-3.694 3.644 0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#7249FD"
          d="M23.743 18.119c2.04 0 3.694-1.631 3.694-3.643 0-2.013-1.654-3.644-3.694-3.644s-3.693 1.631-3.693 3.643c0 2.013 1.653 3.644 3.693 3.644Z"
        />
        <path
          fill="#7249FD"
          d="M23.24 18.61c2.04 0 3.694-1.63 3.694-3.643 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.631-3.694 3.643c0 2.012 1.654 3.644 3.694 3.644Z"
        />
        <path
          fill="#7249FD"
          d="M22.74 19.104c2.04 0 3.694-1.63 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.63-3.693 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#724AFD"
          d="M22.238 19.596c2.04 0 3.694-1.631 3.694-3.643 0-2.012-1.654-3.644-3.694-3.644s-3.694 1.632-3.694 3.644c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#734AFD"
          d="M21.738 20.088c2.04 0 3.693-1.631 3.693-3.643 0-2.013-1.654-3.644-3.694-3.644s-3.693 1.631-3.693 3.644c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#734AFD"
          d="M21.235 20.581c2.04 0 3.694-1.63 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.631-3.693 3.643c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#734AFD"
          d="M20.735 21.073c2.04 0 3.693-1.63 3.693-3.643 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.63-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#734BFD"
          d="M20.232 21.565c2.04 0 3.694-1.631 3.694-3.643 0-2.012-1.654-3.644-3.694-3.644s-3.693 1.632-3.693 3.644c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#744BFD"
          d="M19.732 22.059c2.04 0 3.694-1.632 3.694-3.644 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.631-3.693 3.643c0 2.012 1.653 3.644 3.693 3.644Z"
        />
        <path
          fill="#744BFD"
          d="M19.23 22.55c2.04 0 3.693-1.63 3.693-3.643 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.631-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#754BFD"
          d="M19.226 22.064c2.04 0 3.694-1.631 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.63-3.693 3.643c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#764CFD"
          d="M19.222 21.578c2.04 0 3.694-1.631 3.694-3.643 0-2.012-1.654-3.644-3.694-3.644s-3.693 1.631-3.693 3.644c0 2.012 1.654 3.643 3.693 3.643Z"
        />
        <path
          fill="#774CFD"
          d="M19.218 21.093c2.04 0 3.694-1.63 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.631-3.693 3.643c0 2.012 1.654 3.643 3.693 3.643Z"
        />
        <path
          fill="#784DFD"
          d="M19.213 20.607c2.04 0 3.693-1.63 3.693-3.643 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.63-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#794DFD"
          d="M19.21 20.12c2.04 0 3.693-1.63 3.693-3.642 0-2.013-1.654-3.644-3.694-3.644s-3.693 1.632-3.693 3.644c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#7A4EFD"
          d="M19.206 19.635c2.04 0 3.693-1.632 3.693-3.644 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.631-3.693 3.643c0 2.012 1.654 3.644 3.694 3.644Z"
        />
        <path
          fill="#7B4EFD"
          d="M19.2 19.148c2.04 0 3.694-1.63 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.694 1.631-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#7C4EFD"
          d="M19.196 18.662c2.04 0 3.694-1.631 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.63-3.693 3.643c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#7D4FFD"
          d="M19.192 18.178c2.04 0 3.694-1.631 3.694-3.644 0-2.012-1.654-3.643-3.694-3.643S15.5 12.522 15.5 14.534c0 2.013 1.653 3.644 3.693 3.644Z"
        />
        <path
          fill="#7E4FFD"
          d="M19.187 17.692c2.04 0 3.693-1.632 3.693-3.644 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.631-3.694 3.643c0 2.012 1.654 3.644 3.694 3.644Z"
        />
        <path
          fill="#7F50FD"
          d="M19.183 17.205c2.04 0 3.694-1.63 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.694 1.63-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#8050FD"
          d="M19.18 16.719c2.04 0 3.693-1.631 3.693-3.643 0-2.013-1.654-3.644-3.694-3.644s-3.693 1.631-3.693 3.644c0 2.012 1.654 3.643 3.693 3.643Z"
        />
        <path
          fill="#8150FD"
          d="M19.174 16.233c2.04 0 3.693-1.632 3.693-3.644 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.631-3.694 3.643c0 2.012 1.654 3.644 3.694 3.644Z"
        />
        <path
          fill="#8251FD"
          d="M19.17 15.746c2.04 0 3.693-1.63 3.693-3.643 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.631-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#8351FD"
          d="M19.166 15.262c2.04 0 3.694-1.631 3.694-3.643 0-2.012-1.654-3.644-3.694-3.644s-3.693 1.632-3.693 3.644c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#8552FE"
          d="M19.16 14.776c2.04 0 3.694-1.631 3.694-3.643 0-2.013-1.653-3.644-3.693-3.644s-3.694 1.631-3.694 3.644c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#8652FE"
          d="M19.157 14.29c2.04 0 3.694-1.632 3.694-3.644 0-2.012-1.654-3.643-3.694-3.643s-3.694 1.631-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#8753FE"
          d="M19.153 13.803c2.04 0 3.694-1.63 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.63-3.693 3.643c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#8853FE"
          d="M19.15 13.317c2.04 0 3.693-1.631 3.693-3.643 0-2.012-1.654-3.644-3.694-3.644s-3.693 1.632-3.693 3.644c0 2.012 1.654 3.643 3.693 3.643Z"
        />
        <path
          fill="#8953FE"
          d="M19.144 12.83c2.04 0 3.693-1.63 3.693-3.643 0-2.012-1.653-3.643-3.693-3.643S15.45 7.175 15.45 9.187c0 2.013 1.654 3.644 3.694 3.644Z"
        />
        <path
          fill="#8A54FE"
          d="M19.14 12.344c2.04 0 3.694-1.63 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643S15.446 6.689 15.446 8.7c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#8B54FE"
          d="M19.136 11.86c2.04 0 3.694-1.631 3.694-3.643 0-2.012-1.654-3.644-3.694-3.644s-3.693 1.632-3.693 3.644c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#8C55FE"
          d="M19.13 11.374c2.04 0 3.694-1.631 3.694-3.644 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.631-3.694 3.643c0 2.013 1.654 3.644 3.694 3.644Z"
        />
        <path
          fill="#8D55FE"
          d="M19.127 10.887c2.04 0 3.694-1.63 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.631-3.693 3.643c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#8E55FE"
          d="M19.123 10.401c2.04 0 3.694-1.63 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.63-3.693 3.643c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#8F56FE"
          d="M19.118 9.915c2.04 0 3.693-1.631 3.693-3.643 0-2.012-1.653-3.644-3.693-3.644s-3.694 1.632-3.694 3.644c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#9056FE"
          d="M19.114 9.429c2.04 0 3.694-1.631 3.694-3.644 0-2.012-1.654-3.643-3.694-3.643S15.42 3.773 15.42 5.785c0 2.013 1.654 3.644 3.694 3.644Z"
        />
        <path
          fill="#9157FE"
          d="M19.11 8.944c2.04 0 3.694-1.63 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.63-3.693 3.643c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#9257FE"
          d="M19.105 8.458c2.04 0 3.693-1.631 3.693-3.643 0-2.012-1.653-3.644-3.693-3.644S15.41 2.803 15.41 4.815c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#9358FE"
          d="M19.1 7.972c2.04 0 3.694-1.631 3.694-3.644 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.631-3.694 3.643c0 2.013 1.654 3.644 3.694 3.644Z"
        />
        <path
          fill="#9458FE"
          d="M19.097 7.485c2.04 0 3.694-1.63 3.694-3.643C22.79 1.83 21.137.2 19.097.2s-3.693 1.631-3.693 3.643c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#9659FE"
          d="M18.6 7.981c2.04 0 3.694-1.631 3.694-3.643 0-2.012-1.654-3.644-3.693-3.644-2.04 0-3.694 1.632-3.694 3.644 0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#9759FE"
          d="M18.104 8.478c2.04 0 3.693-1.63 3.693-3.643 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.63-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#995AFE"
          d="M17.605 8.974c2.04 0 3.694-1.631 3.694-3.644 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.631-3.693 3.643c0 2.013 1.653 3.644 3.693 3.644Z"
        />
        <path
          fill="#9A5BFE"
          d="M17.108 9.471c2.04 0 3.694-1.631 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.63-3.693 3.643c0 2.012 1.654 3.643 3.693 3.643Z"
        />
        <path
          fill="#9C5BFE"
          d="M16.612 9.967c2.04 0 3.693-1.632 3.693-3.644 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.631-3.694 3.643c0 2.012 1.654 3.644 3.694 3.644Z"
        />
        <path
          fill="#9D5CFE"
          d="M16.115 10.462c2.04 0 3.694-1.631 3.694-3.643 0-2.012-1.654-3.644-3.694-3.644s-3.694 1.632-3.694 3.644c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#9F5DFE"
          d="M15.618 10.96c2.04 0 3.694-1.632 3.694-3.644 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.63-3.693 3.643c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#A05DFE"
          d="M15.122 11.455c2.04 0 3.693-1.631 3.693-3.643 0-2.013-1.654-3.644-3.693-3.644-2.04 0-3.694 1.631-3.694 3.644 0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#A25EFE"
          d="M14.625 11.952c2.04 0 3.694-1.631 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.694 1.63-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#A35EFE"
          d="M14.128 12.448c2.04 0 3.694-1.632 3.694-3.644 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.631-3.693 3.643c0 2.012 1.653 3.644 3.693 3.644Z"
        />
        <path
          fill="#A55FFE"
          d="M13.632 12.943c2.04 0 3.693-1.631 3.693-3.643 0-2.012-1.653-3.644-3.693-3.644S9.938 7.288 9.938 9.3c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#A760FE"
          d="M13.135 13.44c2.04 0 3.693-1.63 3.693-3.643 0-2.012-1.653-3.643-3.693-3.643S9.44 7.785 9.44 9.797c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#A860FE"
          d="M12.636 13.936c2.04 0 3.694-1.631 3.694-3.643 0-2.013-1.654-3.644-3.694-3.644S8.943 8.28 8.943 10.293c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#AA61FE"
          d="M12.14 14.431c2.04 0 3.693-1.63 3.693-3.643 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.63-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#AB62FE"
          d="M11.643 14.929c2.04 0 3.693-1.632 3.693-3.644 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.631-3.694 3.643c0 2.012 1.654 3.644 3.694 3.644Z"
        />
        <path
          fill="#AD62FF"
          d="M11.146 15.424c2.04 0 3.693-1.631 3.693-3.643 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.63-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#AE63FF"
          d="M10.65 15.921c2.04 0 3.693-1.63 3.693-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.631-3.693 3.643c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#B064FF"
          d="M10.153 16.417c2.04 0 3.693-1.631 3.693-3.643 0-2.012-1.653-3.644-3.693-3.644s-3.694 1.631-3.694 3.644c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#B164FF"
          d="M9.656 16.912c2.04 0 3.694-1.63 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.694 1.63-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#B365FF"
          d="M9.16 17.41c2.04 0 3.693-1.632 3.693-3.644 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.631-3.693 3.643c0 2.012 1.653 3.644 3.693 3.644Z"
        />
        <path
          fill="#B566FF"
          d="M8.663 17.905c2.04 0 3.693-1.631 3.693-3.643 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.63-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#B666FF"
          d="M8.166 18.402c2.04 0 3.693-1.63 3.693-3.643 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.631-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#B867FF"
          d="M7.667 18.898c2.04 0 3.694-1.631 3.694-3.643 0-2.013-1.654-3.644-3.694-3.644s-3.693 1.631-3.693 3.644c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#B967FF"
          d="M7.17 19.393c2.04 0 3.694-1.63 3.694-3.643 0-2.012-1.653-3.643-3.693-3.643s-3.694 1.631-3.694 3.643c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#BB68FF"
          d="M6.674 19.89c2.04 0 3.693-1.63 3.693-3.643 0-2.012-1.653-3.643-3.693-3.643S2.98 14.235 2.98 16.247c0 2.013 1.654 3.644 3.694 3.644Z"
        />
        <path
          fill="#BC69FF"
          d="M6.177 20.386c2.04 0 3.694-1.631 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.63-3.693 3.643c0 2.012 1.653 3.643 3.693 3.643Z"
        />
        <path
          fill="#BE69FF"
          d="M5.68 20.884c2.04 0 3.694-1.632 3.694-3.644 0-2.012-1.654-3.643-3.694-3.643s-3.693 1.631-3.693 3.643c0 2.012 1.653 3.644 3.693 3.644Z"
        />
        <path
          fill="#BF6AFF"
          d="M5.184 21.379c2.04 0 3.693-1.631 3.693-3.643 0-2.012-1.653-3.644-3.693-3.644S1.49 15.724 1.49 17.736c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#C16BFF"
          d="M4.687 21.874c2.04 0 3.694-1.63 3.694-3.643 0-2.012-1.654-3.643-3.694-3.643S.993 16.219.993 18.23c0 2.012 1.654 3.643 3.694 3.643Z"
        />
        <path
          fill="#C26BFF"
          d="M4.19 22.372c2.04 0 3.694-1.631 3.694-3.643 0-2.013-1.654-3.644-3.694-3.644S.497 16.716.497 18.728c0 2.013 1.654 3.644 3.693 3.644Z"
        />
        <path
          fill="#C46CFF"
          d="M3.694 22.867c2.04 0 3.693-1.63 3.693-3.643 0-2.012-1.654-3.643-3.693-3.643C1.654 15.58 0 17.21 0 19.224c0 2.012 1.654 3.643 3.694 3.643Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h124v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MynailLogo;
