import hand from "./hand.png";
import gelLamp from "./gel-lamp.png";
import nailClipper from "./nail-clipper.png";
import pink from "./pink.png";
import purple from "./purple.png";
import pusher from "./pusher.png";
import skyblue from "./skyblue.png";

export const heroImages = {
  hand,
  gelLamp,
  nailClipper,
  pink,
  purple,
  pusher,
  skyblue,
};
