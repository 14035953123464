import React from "react";
import "./ServiceAvailableCheckModal.css";

const ServiceAvailableCheckModal = ({
  onClose,
  isCheckVisible,
  onClickSearch,
  onKeyUp,
  onChange,
  searchResults,
  isInit,
}) => {
  return (
    <div
      className={`service_area_modal_container ${isCheckVisible ? "show" : ""}`}
    >
      <div className="service_area_modal_content">
        <div className="service_area_modal_header">
          <h5>서비스 지역 확인하기</h5>
        </div>
        <div className="service_area_modal_text_container">
          <div className="service_area_search_container">
            <input
              type="text"
              name="areaSearchQuery"
              placeholder="주소를 입력해주세요."
              onChange={onChange}
              onKeyUp={onKeyUp}
            />
            <img
              className="search_icon"
              onClick={onClickSearch}
              src="/search_icon.png"
            ></img>
          </div>
          <div className="search_result_container">
            {searchResults.length > 0 ? (
              searchResults.map((item, index) => (
                <div key={index} className="search_result_item_container">
                  <span
                    className={`search_result_badge ${item.availableAddress ? "available" : "unavailable"}`}
                  >
                    {item.availableAddress
                      ? "서비스 가능 지역"
                      : "서비스 불가 지역"}
                  </span>
                  <div className="search_result_address_area">
                    <div className="search_result_address">
                      <span className="search_result_address_type">도로명</span>
                      {item.roadAddr}
                    </div>
                    <div className="search_result_address">
                      <span className="search_result_address_type">지번</span>
                      {item.jibunAddr}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h3 className="search_result_no_result">
                {isInit ? "" : "검색 결과가 없습니다."}
              </h3>
            )}
          </div>
        </div>
        <button className="modal_close_button" onClick={onClose}>
          닫기
        </button>
      </div>
    </div>
  );
};

export default ServiceAvailableCheckModal;
