import React, { useEffect, useState } from "react";
import "./HeroBlock.css";
import { heroImages } from "../../assets/hero-assets/hero-product-images";
import { screenImages } from "../../assets/hero-assets/hero-screen-images";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  headerBottomBlackText,
  headerBottomText,
  headerTopText,
} from "../../assets/hero-assets/header-text";
import StartIcon from "../../assets/icon-components/ButtonIcon/StartIcon";
import HeroDesktopAnimation from "../../components/HeroBackgroundAnimation/HeroDesktopAnimation/HeroDesktopAnimation";
import HeroMobileAnimation from "../../components/HeroBackgroundAnimation/HeroMobileAnimation/HeroMobileAnimation";

const HeroBlock = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  const [index, setIndex] = useState(0);
  const totalItems = headerTopText.length;

  // get index of headerText array
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % totalItems);
    }, 2300);

    return () => clearInterval(interval);
  }, [totalItems]);

  // check screen size
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.matchMedia("(max-width: 1080px)").matches);
    };

    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <div className="mynail_hero_block_container" ref={ref}>
      <div className="mynail_hero_background_desktop">
        <HeroDesktopAnimation inView={inView} />
      </div>

      <div className="mynail_hero_background_mobile">
        <HeroMobileAnimation inView={inView} />
      </div>

      <div className="mynail_hero_content_container">
        <div className="mynail_hero_content">
          <div className="mynail_hero_phone">
            <img src={heroImages.hand} alt="hero_hand" />
            <div className="mynail_hero_slider">
              <div className="mynail_hero_slider_list">
                <div className="screen">
                  <img src={screenImages.screen1} alt="hero_phone_screen_1" />
                </div>
                <div className="screen">
                  <img src={screenImages.screen2} alt="hero_phone_screen_2" />
                </div>
                <div className="screen">
                  <img src={screenImages.screen3} alt="hero_phone_screen_3" />
                </div>
                <div className="screen">
                  <img src={screenImages.screen4} alt="hero_phone_screen_4" />
                </div>
                <div className="screen">
                  <img src={screenImages.screen5} alt="hero_phone_screen_5" />
                </div>
                <div className="screen">
                  <img src={screenImages.screen6} alt="hero_phone_screen_6" />
                </div>
                <div className="screen">
                  <img src={screenImages.screen1} alt="hero_phone_screen_7" />
                </div>
              </div>
            </div>
          </div>

          <div className="mynail_hero_text_container">
            <div className="mynail_hero_text_wrapper">
              <div className="mynail_hero_header_text">
                <motion.p
                  key={`top-${index}`}
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 0 }}
                  transition={{ duration: 0.3, ease: "easeOut", delay: 2 }}
                >
                  {`${headerTopText[index]}\n${headerBottomText[index]}`}
                  <span>{headerBottomBlackText[index]}</span>
                </motion.p>

                <motion.p
                  key={`top-${(index + 1) % totalItems}`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3, ease: "easeOut", delay: 2 }}
                >
                  {`${headerTopText[(index + 1) % totalItems]}\n${headerBottomText[(index + 1) % totalItems]}`}
                  <span>{headerBottomBlackText[(index + 1) % totalItems]}</span>
                </motion.p>
              </div>
              <div className="mynail_hero_sub_text">
                <p>국내 최초 네일 키트 대여 앱, 마이네일</p>
              </div>
            </div>

            <div
              className="start_icon_container"
              onClick={() =>
                window.open("https://abr.ge/@mynail/airpage", "_blank")
              }
            >
              <StartIcon
                width={isMobile ? 124 : 148}
                height={isMobile ? 40 : 44}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBlock;
