export const headerTopText = [
  "다양한 네일 재료들",
  "셀프네일이 어렵다면",
  "다양한 네일 디자인",
  "내가 원하는 스타일",
  "내가 작업한 네일 디자인과",
  "재료 공유 DM은 그만",
];

export const headerBottomText = [
  "내 맘대로 ",
  "동영상 강좌로 ",
  "아이디어를 ",
  "마이네일에 ",
  "재료 정보를 ",
  "버튼 한 번에 ",
];

export const headerBottomBlackText = [
  "골라봐요",
  "극복",
  "참고해봐요",
  "다 있다",
  "아카이빙",
  "정보 획득",
];

export const headerText = headerTopText.map((headerTopText, index) => {
  return `${headerTopText}\n${headerBottomText[index]}`;
});
