import React, { useState } from "react";
import "./MapBlock.css";
import Map from "../../assets/map-assets/Map/Map";
import SearchMapIcon from "../../assets/icon-components/SearchMapIcon/SearchMapIcon";
// import { createPortal } from "react-dom";
// import { ServiceAvailabilityCheckModal } from "./ServiceAvailabiltyCheckModal";

import ServiceAvailableCheckModal from "../../components/Modals/serviceAvailableCheckModal/ServiceAvailableCheckModal";
import MapMobile from "../../assets/map-assets/Map/Map_mobile";

const MapBlock = () => {
  const [isInit, setInit] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [showCheckModal, setShowCheckModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const openCheckModal = () => {
    setInit(true);
    setShowCheckModal(true);
  };

  const closeCheckModal = () => {
    setShowCheckModal(false);
  };

  const clickSearch = async () => {
    if (searchQuery == undefined) {
      alert("검색어를 입력해주세요.");
      return false;
    }

    const response = await fetch(
      `/api/v1/juso/getAddrApi?currentPage=1&countPerPage=10&keyword=${searchQuery}&dawnDelivery=N`,
    );

    const data = await response.json();
    setSearchResults(data);
    setInit(false);
  };

  const keyUp = (ev) => {
    if (ev.keyCode == 13) clickSearch();
  };

  const handleInputChange = (ev) => {
    setSearchQuery(ev.target.value);
  };

  return (
    <div className="mynail_map_block">
      <div className="mynail_map_content_container">
        <div className="map_container_desktop">
          <Map />
        </div>
        <div className="map_container_mobile">
          <MapMobile />
        </div>
        <div className="map_text_container">
          <div className="map_text_top">
            <p>
              주문한 네일키트는 문 앞까지 <span>새벽배송</span>
            </p>
          </div>
          <div className="map_main_text">
            <h1>
              <span className="purple">우리 동네</span>
              {`도\n`}
              <span className="deep_purple">이용 가능</span>할까요?
            </h1>
          </div>
          <button
            className="search_map_button"
            onClick={openCheckModal}
            style={{ cursor: "pointer" }}
          >
            <SearchMapIcon />
          </button>
        </div>
      </div>
      <ServiceAvailableCheckModal
        isCheckVisible={showCheckModal}
        onClose={closeCheckModal}
        onClickSearch={clickSearch}
        onChange={handleInputChange}
        onKeyUp={keyUp}
        searchResults={searchResults}
        isInit={isInit}
      />
    </div>
  );
};

export default MapBlock;
