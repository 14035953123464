import React from "react";
import "./ReviewBlock.css";
import ReviewCard from "../../components/ReviewCard/ReviewCard";
import { userImages } from "../../assets/review-assets/user-images";

const ReviewBlock = () => {
  const reviewList = [
    {
      header: `비싼 네일 재료 샀다가 실패하면 어떡하지?\n고민하는 분들에게 강추!`,
      content: `셀프네일을 하는 분들이나 네일 도전해 보고 싶은데 비싼 재료들 다 샀다가 실패하면 어떡하지? 라는 고민을 하시는 분들에게 강추하는 앱입니다. 대여 서비스는 사는 거보다 훨씬 저렴해서 이것저것 도전해 보기도 좋고, 테스트해 보기도 좋을 것 같아요. 네일 강좌나 꿀팁도 있어서 초보 분들 강추합니다!`,
      icon: userImages.user1,
      name: "신*진",
    },
    {
      header: `네일재료는 모으다보면 끝이 없는데.. 그 걱정을 덜어준 마이네일`,
      content: `마이네일에서 대여하면 집에 네일용품을 쌓아두지 않고도 누구나 방구석 네일리스트가 될 수 있어요! 길잡이같은 클래스영상에 초보들도 알기쉽게 기초부터 설명을 잘해주셨더라구요. 그리고 네일러들이 피드에 디자인과 함께 사용제품 꿀팁을 적어두셔서 인스타그램에서는 쉽게 얻을 수 없었던 정보를 확인할 수 있다는게 아주 큰 장점~ 네일러 모여라!!`,
      icon: userImages.user2,
      name: "귤*쥐",
    },
    {
      header: `네일 좋아하는 분들은\n꼭 이용해 보셔야 하는 앱!`,
      content: `셀프네일을 좋아해서 이것저것 찾아보다 마이네일 앱을 알게되었어요! 처음엔 재료 대여를 위해 설치한 어플이었는데, 사용한 네일 제품들을 한 눈에 알 수 있는 커뮤니티 서비스도 너무 잘되어있어서 좋더라구요 ㅎㅎ 시간 가는 줄 모르고 구경했다는.. 직접 올리는 것도 넘 재밌어요! 네일러들을 위한 유일무이한 좋은 어플 만들어주셔서 너무너무 감사합니다.`,
      icon: userImages.user1,
      name: "어*싱",
    },
    {
      header: `마이네일은 다양한 네일 디자인과 함께 사용 재료도 확인할 수 있어요`,
      content: `셀프네일을 할 때 가장 막막한 것은 어떻게 시작할지와 어떤 디자인을 어떻게 할지 인 것 같아요~! 특히 인*타그램에서 예쁜 디자인을 보아도 어떤 재료를 사용했는지 적혀 있지 않은 경우가 많은데 마이네일에서는 다양한 디자인과 함께 사용한 재료도 알 수 있어서 디자인 할 때 많은 도움이 되어 너무 좋아요!`,
      icon: userImages.user2,
      name: "so*lef",
    },
    {
      header: `셀프네일러에게 필수 어플!!`,
      content: `셀프네일하는 사람들한테 가장 필요한 서비스들만 모아 둬서 너무 좋아요! 대여하기는 기본 필수재료부터 다양한 컬러, 부자재들도 써볼 수 있어서 자주 이용할 것 같아요. 피드는 여러 네일러들의 다양한 디자인을 한꺼번에 모아서 확인할 수 있어서 디자인 구상하기 편할 것 같고, 클래스 영상도 유용할 것 같아요!`,
      icon: userImages.user1,
      name: "다*",
    },
    {
      header: `셀프네일러가 요즘 핫한 트렌드 스타일 네일을 배울 수 있는 앱!`,
      content: `셀프네일러들이 손쉽게 좋은 재료들을 배송받아 셀프네일을 할 수 있도록 만들어진 신박한 앱! 다른 셀프 네일러들의 작품도 보고, 직접 만든 작품도 올리고, 요즘 핫한 트렌디 스타일 네일도 배워보고 이것저것 따질 것 없이 다운로드해야 하는 앱이에요!`,
      icon: userImages.user2,
      name: "so*ung",
    },
    {
      header: `셀프네일러면 당장 깔아야할 앱`,
      content: `셀프네일 입문하려는데 재료가 고민이고, 부담되는 분이라면 대여 기능이 너무 유용할 거예요. 실제로 대여해 봤을 때 구성도 알차고 깔끔하고 재료 종류도 생각보다 다양해서 좋더라고요. 커뮤니티도 점점 활성화되고 있어서 네일인들에게 딱 적합한 인*타그램이새로 생긴 느낌? 더 많은 분들이 써보셨으면 하는 앱이에요`,
      icon: userImages.user2,
      name: "넬*",
    },
  ];

  return (
    <div className="review_block">
      <div className="review_title_container">
        <h1>
          {`내 손 안의 네일샵,\n 마이네일`} <span>고객후기</span>
        </h1>
        <div className="review_content">
          <p>
            {`터치 한 번에 우리 집이 네일샵으로 변신!\n소중한 마이네일 고객님들의 이야기를 들려드려요.`}
          </p>
        </div>
      </div>

      <div className="review_carousel_container">
        <div className="review_carousel">
          {reviewList.map((review, index) => (
            <ReviewCard
              key={index}
              header={review.header}
              content={review.content}
              icon={review.icon}
              name={review.name}
            />
          ))}
        </div>
        <div className="review_carousel">
          {reviewList.map((review, index) => (
            <ReviewCard
              key={index}
              header={review.header}
              content={review.content}
              icon={review.icon}
              name={review.name}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReviewBlock;
