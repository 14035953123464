const BurgerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        stroke="#1C1C1C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 5h18M3 12h18M3 19h18"
      />
    </svg>
  );
};

export default BurgerIcon;
