import React from "react";

function InstagramIcon({ width = 50, height = 50 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 50 50"
    >
      <rect width={width} height={width} fill="#fff" rx="24"></rect>
      <rect
        width="49"
        height="49"
        x="0.5"
        y="0.5"
        stroke="#C46CFF"
        strokeOpacity="0.3"
        rx="23.5"
      ></rect>
      <g fill="#C46CFF" clipPath="url(#clip0_85_1013)">
        <path d="M25 15.16c3.206 0 3.586.015 4.847.071 1.172.052 1.805.249 2.226.413a3.71 3.71 0 011.379.895c.421.422.68.82.895 1.378.164.422.36 1.06.412 2.227.057 1.265.07 1.645.07 4.847 0 3.206-.013 3.586-.07 4.846-.051 1.172-.248 1.805-.412 2.227a3.71 3.71 0 01-.895 1.378 3.69 3.69 0 01-1.379.895c-.421.165-1.059.361-2.226.413-1.266.056-1.645.07-4.847.07-3.206 0-3.586-.014-4.847-.07-1.172-.052-1.805-.248-2.226-.413a3.711 3.711 0 01-1.379-.895 3.693 3.693 0 01-.895-1.378c-.164-.422-.36-1.06-.412-2.227-.057-1.265-.07-1.645-.07-4.846 0-3.207.013-3.586.07-4.847.051-1.172.248-1.805.412-2.227.216-.558.478-.96.895-1.378a3.69 3.69 0 011.379-.895c.421-.164 1.059-.361 2.226-.413 1.261-.056 1.64-.07 4.847-.07zM25 13c-3.258 0-3.666.014-4.945.07-1.275.057-2.152.263-2.911.558a5.858 5.858 0 00-2.128 1.388 5.88 5.88 0 00-1.388 2.123c-.295.764-.501 1.636-.558 2.911-.056 1.284-.07 1.692-.07 4.95s.014 3.666.07 4.945c.057 1.275.263 2.152.558 2.911.31.792.717 1.463 1.388 2.128a5.867 5.867 0 002.123 1.383c.764.296 1.636.502 2.911.558 1.28.056 1.688.07 4.945.07 3.258 0 3.666-.014 4.946-.07 1.275-.056 2.151-.262 2.91-.558a5.866 5.866 0 002.124-1.383 5.866 5.866 0 001.383-2.123c.295-.764.501-1.636.558-2.911.056-1.28.07-1.688.07-4.945 0-3.258-.014-3.666-.07-4.946-.057-1.275-.263-2.151-.558-2.91a5.62 5.62 0 00-1.374-2.133 5.866 5.866 0 00-2.123-1.383c-.764-.295-1.636-.502-2.911-.558C28.666 13.015 28.258 13 25 13z"></path>
        <path d="M25 18.836A6.166 6.166 0 0018.836 25 6.166 6.166 0 0025 31.164 6.166 6.166 0 0031.164 25 6.166 6.166 0 0025 18.836zm0 10.162A3.999 3.999 0 1125.001 21 3.999 3.999 0 0125 28.998zM32.847 18.592a1.44 1.44 0 11-2.879 0 1.44 1.44 0 012.879 0z"></path>
      </g>
      <defs>
        <clipPath id="clip0_85_1013">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="translate(13 13)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default InstagramIcon;
