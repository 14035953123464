import React from "react";
import MynailLogo from "../../../assets/icon-components/MynailLogo/MynailLogo";
import "./DesktopNavigationBar.css";

const DesktopNavigationBar = ({ scrollToBlock, refs }) => {
  return (
    <nav className="nav">
      <div className="nav_inner_container">
        <div className="nav_lt">
          <div onClick={() => scrollToBlock(refs.productRef)}>
            <p>서비스소개</p>
          </div>
          <div onClick={() => scrollToBlock(refs.reviewRef)}>
            <p>고객후기</p>
          </div>
        </div>
        <div onClick={() => scrollToBlock(refs.homeRef)}>
          <MynailLogo />
        </div>
        <div className="nav_rt">
          <div onClick={() => scrollToBlock(refs.mapRef)}>
            <p>이용 가능 지역</p>
          </div>
          <div>
            <div onClick={() => scrollToBlock(refs.inquireRef)}>
              <p>입점문의</p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default DesktopNavigationBar;
