import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import TextIconCard from "../TextIconCard/TextIconCard";
import "./FeatureCard.css";

export default function FeatureCard({
  image,
  upperText,
  lowerText,
  rightImage,
  lottieStar1,
  index,
}) {
  const [isMobile, setIsMobile] = useState(false);

  // check screen size
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.matchMedia("(max-width: 1080px)").matches);
    };

    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <div className="mynail_feature_card_container">
      {!rightImage || isMobile ? (
        <>
          <div className="feature_card_img_container">
            <motion.div
              className="feature_img_background"
              style={{
                backgroundImage: `url(${image})`,
              }}
              whileHover={{ scale: 0.96 }}
              transition={{
                type: "spring",
                stiffness: 100,
                damping: 65,
                mass: 1,
              }}
            />
          </div>
          <div className="feature_card_text_icon_container">
            <TextIconCard
              upperText={upperText}
              lowerText={lowerText}
              lottieStar1={lottieStar1}
              index={index}
            />
          </div>
        </>
      ) : (
        <>
          <div className="feature_card_text_icon_container">
            <TextIconCard
              upperText={upperText}
              lowerText={lowerText}
              lottieStar1={lottieStar1}
              index={index}
            />
          </div>
          <div className="feature_card_img_container">
            <motion.div
              className="feature_img_background"
              style={{
                backgroundImage: `url(${image})`,
              }}
              whileHover={{ scale: 0.96 }}
              transition={{
                type: "spring",
                stiffness: 100,
                damping: 65,
                mass: 1,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}
