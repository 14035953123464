import React from "react";
import DesktopNavigationBar from "../DesktopNavigationBar/DesktopNavigationBar";
import MobileNavigationBar from "../MobileNavigationBar/MobileNavigationBar";
import "./MynailNavigation.css";

const MynailNavigation = ({ scrollToBlock, refs }) => {
  return (
    <>
      <div className="mynail_desktop_nav_container">
        <DesktopNavigationBar scrollToBlock={scrollToBlock} refs={refs} />
      </div>
      <div className="mynail_mobile_nav_container">
        <MobileNavigationBar scrollTo={scrollToBlock} refs={refs} />
      </div>
    </>
  );
};

export default MynailNavigation;
