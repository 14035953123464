import React from "react";
import "./FeatureText1.css";

const FeatureText1 = () => {
  return (
    <>
      <div className="feature_header_text_container">
        <p>{`진짜 가성비 장난없는\n네일 키트 대여 서비스`}</p>
      </div>
      <div className="feature_content_text_container">
        <p>
          일반 네일샵 최소 <span className="price">{`50,000원\n`}</span>
          <span className="mynail_text">마이네일 12,900원 ~</span>
        </p>
      </div>
    </>
  );
};

export default FeatureText1;
