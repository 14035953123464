import screen1 from "./screen1.png";
import screen2 from "./screen2.png";
import screen3 from "./screen3.png";
import screen4 from "./screen4.png";
import screen5 from "./screen5.png";
import screen6 from "./screen6.png";

export const screenImages = {
  screen1,
  screen2,
  screen3,
  screen4,
  screen5,
  screen6,
};
