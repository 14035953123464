import React, { useState } from "react";
import "./InquireForm.css";
// import InquireIconBackground from "../../assets/icon-components/InquireIcon/InquireIconBackground.png";
import InquireSubmitModal from "../Modals/InquireSubmitModal/InquireSubmitModal";

const InquireForm = () => {
  const [showModal, setShowModal] = useState(false);
  const ACCESS_KEY = process.env.REACT_APP_EMAIL_ACCESS_KEY;

  const inquireTextList = [
    {
      title: "브랜드명(회사명)",
      content: "브랜드명(회사명)을 입력해주세요.",
      name: "brand",
    },
    {
      title: "담당자명",
      content: "담당자명을 입력해주세요.",
      name: "manager",
    },
    {
      title: "이메일 주소",
      content: "담당자분의 이메일 주소를 입력해주세요.",
      name: "email",
    },
    {
      title: "휴대폰 번호",
      content: "담당자분의 휴대폰 번호를 입력해주세요.",
      name: "phone",
    },
    {
      title: "문의 내용",
      content: "입점 문의 내용을 입력해주세요.",
      name: "inquiry",
    },
  ];

  const [formData, setFormData] = useState({
    brand: "",
    manager: "",
    email: "",
    phone: "",
    inquiry: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", ACCESS_KEY);

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (response.status === 200) {
      setShowModal(true);
      event.target.reset();
    } else {
      console.log("Error", data);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setFormData({
      brand: "",
      manager: "",
      email: "",
      phone: "",
      inquiry: "",
    });
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="inquire_input_container">
          {inquireTextList[0].title}
          <div className="placeholder_box">
            <input
              type="text"
              name={inquireTextList[0].name}
              placeholder={inquireTextList[0].content}
              value={formData.brand}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="inquire_input_container">
          {inquireTextList[1].title}
          <div className="placeholder_box">
            <input
              type="text"
              name={inquireTextList[1].name}
              placeholder={inquireTextList[1].content}
              value={formData.manager}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="inquire_input_container">
          {inquireTextList[2].title}
          <div className="placeholder_box">
            <input
              type="email"
              name={inquireTextList[2].name}
              placeholder={inquireTextList[2].content}
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="inquire_input_container">
          {inquireTextList[3].title}
          <div className="placeholder_box">
            <input
              type="text"
              name={inquireTextList[3].name}
              placeholder={inquireTextList[3].content}
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="inquire_input_container">
          {inquireTextList[4].title}
          <div className="placeholder_box">
            <input
              type="text"
              name={inquireTextList[4].name}
              placeholder={inquireTextList[4].content}
              value={formData.inquiry}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <button
          className="submit_button"
          type="submit"
          style={{ cursor: "pointer" }}
        >
          <div className="sumbit_button_background"></div>
          <p>입점 문의하기</p>
        </button>
      </form>

      <InquireSubmitModal isVisible={showModal} onClose={closeModal} />
    </>
  );
};

export default InquireForm;
