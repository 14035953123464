import React from "react";
import "./InquireSubmitModal.css";

const InquireSubmitModal = ({ onClose, isVisible }) => {
  return (
    <div
      className={`inquire_submit_modal_container ${isVisible ? "show" : ""}`}
    >
      <div className="submit_modal_content">
        <div className="submit_modal_text_container">
          <p>{`입점 문의하기가 완료되었습니다.\n신속히 연락드리겠습니다.`}</p>
        </div>

        <button className="modal_close_button" onClick={onClose}>
          확인
        </button>
      </div>
    </div>
  );
};

export default InquireSubmitModal;
