import { useEffect, useState } from "react";
import { productImages } from "../../assets/feature-block-images";
import FeatureCard from "../../components/FeatureCard/FeatureCard";
import "./FeatureBlock.css";

const FeatureBlock = () => {
  const [isMobile, setIsMobile] = useState(false);

  // check screen size
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.matchMedia("(max-width: 1080px)").matches);
    };

    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const featureList = [
    {
      image: productImages.productImage1,
      upperText: `진짜 가성비 장난없는\n네일 키트 대여 서비스`,
      lowerText: `일반 네일샵 최소 50,000월\n마이네일 12,900원 ~`,
      rightImage: false,
      lottieStar1: true,
    },
    {
      image: productImages.productImage2,
      upperText: `소중한 마이네일 고객님을 위한\n위생 관리 3단계`,
      lowerText: `어렵고 번거로운 위생관리는\n마이네일이 책임질게요!`,
      rightImage: true,
      lottieStar1: false,
    },
    {
      image: isMobile
        ? productImages.productImage3Mobile
        : productImages.productImage3,
      upperText: `셀프 네일이 처음이라면\n영상으로 네일 독학`,
      lowerText: `난이도별 무료 네일 강좌로\n초보자도 가능해요!`,
      rightImage: false,
      lottieStar1: false,
    },
    {
      image: productImages.productImage4,
      upperText: `나만의 네일아트 디자인과\n네일 재료 정보 공유`,
      lowerText: `마음껏 네일정보 공유하고 \n디자인 영감도 받아가세요!`,
      rightImage: true,
      lottieStar1: true,
    },
  ];

  return (
    <div className="feature_block">
      <div className="feature_upper_text_container">
        <div className="feature_upper_text_header_container">
          <h1>
            <span>{`주문한 네일키트는\n `}</span>
            로켓보다 빠르게 <span>당일배송</span>
          </h1>
        </div>
        <div className="feature_upper_text_content_container">
          <p>오전 11시 이전 주문 시 당일배송 가능한 네일키트!</p>
          <p>{`독보적인 나만의 디자인으로\n 세상에 하나뿐인 소중한 네일을 만들어보세요.`}</p>
        </div>
      </div>
      <div className="feature_product_block">
        <div className="feature_cards_container">
          {featureList.map((item, index) => (
            <FeatureCard
              key={index}
              image={item.image}
              upperText={item.upperText}
              lowerText={item.lowerText}
              rightImage={item.rightImage}
              lottieStar1={item.lottieStar1}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeatureBlock;
