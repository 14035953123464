import productImage1 from "./product-image1.png";
import productImage2 from "./product-image2.png";
import productImage3 from "./product-image3.png";
import productImage3Mobile from "./product-image3-mobile.png";
import productImage4 from "./product-image4.png";

export const productImages = {
  productImage1,
  productImage2,
  productImage3,
  productImage3Mobile,
  productImage4,
};
