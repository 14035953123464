import React, { useEffect, useState } from "react";
import MynailLogo from "../../assets/icon-components/MynailLogo/MynailLogo";
import InstagramIcon from "../../assets/icon-components/SocialMediaIcons/InstagramIcon/InstagramIcon";
import NaverIcon from "../../assets/icon-components/SocialMediaIcons/NaverIcon/NaverIcon";
import "./FooterBlock.css";

const FooterBlock = () => {
  const [isMobile, setIsMobile] = useState(false);

  // check screen size
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <div className="mynail_footer_container">
      <div className="footer_logo_info_container">
        <MynailLogo />
        <div className="footer_info_wrapper">
          <div className="footer_text_top">
            <p>
              <span className="footer_bold">회사명 </span>주식회사 마이네일
              <span className="footer_line"> | </span>
              <span className="footer_bold">대표자 </span>박효경
            </p>
          </div>
          <div className="footer_text_mid">
            <p>
              <span className="footer_bold">주소 </span>
              {`서울 금천구 서부샛길 638 대륭테크노타운7차, 703-1호\n`}
              <span className="footer_line_desktop"> | </span>
              <span className="footer_bold">사업자등록번호 </span>박효경
            </p>
          </div>
          <div className="footer_text_bottome">
            <p>
              <span className="footer_bold">통신판매업신고번호 </span>
              {`제2023-대구중구-0271호\n`}
              <span className="footer_line_desktop"> | </span>
              <span className="footer_bold">고객센터 </span>1600-4107
              <span className="footer_line"> | </span>
              <span className="footer_bold">제휴문의 </span>info@mynail.kr
            </p>
          </div>
        </div>
      </div>

      <div className="footer_navigation_container">
        <div className="footer_sns_container">
          <div
            className="icon_container"
            onClick={() =>
              window.open("https://www.instagram.com/mynail.kr/", "_blank")
            }
          >
            {isMobile ? (
              <InstagramIcon width={40} height={40} />
            ) : (
              <InstagramIcon />
            )}
          </div>
          <div
            className="icon_container"
            onClick={() =>
              window.open("https://smartstore.naver.com/my_nail", "_blank")
            }
          >
            {isMobile ? <NaverIcon width={40} height={40} /> : <NaverIcon />}
          </div>
        </div>
        <div className="footer_contact_container">
          <a href="https://mynail.oopy.io/" target="_blank" rel="noreferrer">
            회사소개
          </a>
          <span className="footer_line">&nbsp;|&nbsp;</span>
          <a
            href="https://mynail.oopy.io/9b8c6dd8-3851-4e9c-bde9-95c767e552cf"
            target="_blank"
            rel="noreferrer"
          >
            개인정보수집동의
          </a>
          <span className="footer_line">&nbsp;|&nbsp;</span>
          <a
            href="https://mynail.oopy.io/terms"
            target="_blank"
            rel="noreferrer"
          >
            이용약관
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterBlock;
