import React from "react";
import "./InquireBlock.css";
import inquireImage from "../../assets/inquire-assets/inquire_image.png";
import InquireForm from "../../components/InquireForm/InquireForm";

const InquireBlock = () => {
  return (
    <div className="inquire_block_container">
      <div className="inquire_text_container">
        <h1>입점 문의</h1>
        <p>
          {`국내/해외 네일 브랜드 담당자님!\n마이네일과 함께 네일러에게 제품을 알리고 싶으신가요?`}
        </p>
      </div>

      <div className="inquire_content_container">
        <div className="inquire_image_container">
          <img src={inquireImage} alt="inquire_img" />
        </div>

        <div className="inquire_form_container">
          <InquireForm />
        </div>
      </div>
    </div>
  );
};

export default InquireBlock;
