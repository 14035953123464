import React from "react";

function NaverIcon({ width = 50, height = 50 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 50 50"
    >
      <rect width={width} height={height} fill="#fff" rx="24"></rect>
      <rect
        width="49"
        height="49"
        x="0.5"
        y="0.5"
        stroke="#C46CFF"
        strokeOpacity="0.3"
        rx="23.5"
      ></rect>
      <path
        fill="#C46CFF"
        d="M17.068 13.095h16.053a3.976 3.976 0 013.974 3.974V33.12a3.976 3.976 0 01-3.974 3.974H17.068a3.976 3.976 0 01-3.973-3.974V17.07a3.976 3.976 0 013.973-3.974z"
      ></path>
      <path
        fill="#fff"
        d="M19.752 19.603V16.86a.607.607 0 111.214 0v2.742a4.13 4.13 0 004.126 4.125 4.13 4.13 0 004.125-4.125V16.86a.607.607 0 111.215 0v2.742a5.346 5.346 0 01-5.34 5.34 5.348 5.348 0 01-5.34-5.34zM33.463 33.65h-1.376l-2.359-2.896v2.896H28.14v-4.876h1.375l2.36 2.952v-2.952h1.588v4.876z"
      ></path>
    </svg>
  );
}

export default NaverIcon;
