import React from "react";
import Lottie from "lottie-react";
import star1Animation from "../../assets/lottie-animations/1 Star.json";
import star2Animation from "../../assets/lottie-animations/2 Stars.json";
import "./TextIconCard.css";
import FeatureText1 from "../FeatureText/FeatureText1";

const TextIconCard = ({ upperText, lowerText, lottieStar1, index }) => {
  const [firstPart, secondPart] = upperText.split("\n");

  return (
    <div className="ti_container">
      <div className="ti_content">
        <div className="ti_icon_container_desktop">
          {lottieStar1 ? (
            <div className="lottie_star1">
              <Lottie name="star1" animationData={star1Animation} />
            </div>
          ) : (
            <div className="lottie_star2">
              <Lottie name="star2" animationData={star2Animation} />
            </div>
          )}
        </div>
        <div className="ti_icon_container_mobile">
          <Lottie name="star1" animationData={star1Animation} />
        </div>

        <div className="ti_text_container">
          {index === 0 ? (
            <FeatureText1 />
          ) : (
            <>
              <div className="ti_upper_text_container">
                <p>
                  {firstPart}
                  <br />
                  {secondPart && (
                    <span style={{ color: "#C46CFF" }}>{` ${secondPart}`}</span>
                  )}
                </p>
              </div>
              <div className="ti_lower_text_container">
                <p>{lowerText}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TextIconCard;
